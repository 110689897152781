<template>
  <div class="SensitiveMonitor">
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <!-- 起始日期 -->
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.startTime"
          type="date"
          :placeholder="$t('SecurityMonitor.Violation.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.endTime"
          type="date"
          :placeholder="$t('SecurityMonitor.Violation.SelectDate')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <!-- 行为类型 -->
        <el-select
          v-model="formInline.reason"
          :placeholder="$t('public.BehaviorType')"
          style="width: 100%"
          size="small"
          class="placeInput"
          @change="chang1"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.type"
            :label="$t(`SecurityMonitor.Violation.TypeOptions.${item.label}`)"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <!-- 行为级别 -->
        <el-select
          style="width: 100%"
          v-model="formInline.level"
          :placeholder="$t('public.Behaviorlevel')"
          size="small"
          class="placeInput"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="$t(`SecurityMonitor.Violation.LevelOptions.${item.label}`)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <!-- 位置 -->
      <el-col :span="3">
        <el-cascader
          size="small"
          style="width: 100%"
          :options="cityOptions"
          collapse-tags
          :props="props"
          v-model="formInline.location"
          :placeholder="$t('public.Position')"
          filterable
          class="placeInput"
        ></el-cascader>
      </el-col>
        <select-input
          :options="devNameOptions"
          @selectSearchChange="selectSearchChange"
          @searchList="onSubmit"
        ></select-input>
      <el-col :span="3">
        <el-button size="small" type="primary" @click="advancedBtn">{{
          $t("public.Derive")
        }}</el-button>
      </el-col>
    </el-row>
    <!-- 点击选中只显示对应内容 -->
    <el-row :gutter="4" class="elRow" style="position: relative">
      <div
        style="
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 5px;
          top: -32px;
          cursor: pointer;
        "
      >
        <!-- 弹出框 -->
        <el-popover trigger="click" popper-class="LinkageTable">
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="$t(`SecurityMonitor.Violation.Tablelinkage.${item.eng}`)"
              :checked="item.isChecked"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
              >{{
                $t(`SecurityMonitor.Violation.Tablelinkage.${item.name}`)
              }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="@/images/showConfig.png"
            alt=""
            style="
              width: 20px;
              height: 20px;
            "
          />
        </el-popover>
      </div>
      <!-- 表格 -->
      <el-table
        size="mini"
        ref="myTable"
        :data="tableData"
        stripe
        fit
        tooltip-effect="dark"
        class="tableClass"
        @sort-change="sort"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        :default-sort="{ prop: 'CREATETIME', order: 'ascending' }"
      >
        <template>
          <el-table-column
            v-if="$filter.nameFilter(formThead, tradeSelections)"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              width="120"
              v-for="(item, index) in formThead"
              :key="index"
              :label="item.name"
            >
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('public.DeviceName')"
          prop="DEVIVENAME"
          v-if="tradeSelections[tradeSelections.length - 17].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.devicename }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('public.BehaviorType')"
          prop="TYPE"
          v-if="tradeSelections[tradeSelections.length - 16].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.type }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('public.Behaviorlevel')"
          width="120"
          prop="LEVEL"
          v-if="tradeSelections[tradeSelections.length - 15].isChecked"
        >
          <template slot-scope="scope">
            <span
              style="color: #d9001b; cursor: pointer"
              v-if="scope.row.level == '3'"
              >{{ $t("SecurityMonitor.Violation.Tall") }}</span
            >
            <span
              style="color: #f59a23; cursor: pointer"
              v-if="scope.row.level == '2'"
              >{{ $t("SecurityMonitor.Violation.Centre") }}</span
            >
            <span
              style="color: #00cdcd; cursor: pointer"
              v-if="scope.row.level == '1'"
              >{{ $t("SecurityMonitor.Violation.Low") }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.SystemVersion')"
          prop="OS_VERSION"
          v-if="tradeSelections[tradeSelections.length - 14].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.osVersion }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Position')"
          v-if="tradeSelections[tradeSelections.length - 13].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.location }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.LatitudeLongitude')"
          v-if="tradeSelections[tradeSelections.length - 12].isChecked"
        >
          <template slot-scope="scope">
            <span
              class="detaildesc"
              v-if="scope.row.longitude == '' || scope.row.latitude == ''"
            ></span>
            <span class="detaildesc" v-else
              >{{ scope.row.longitude }},{{ scope.row.latitude }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.InternetMethod')"
          prop="NET_TYPE"
          v-if="tradeSelections[tradeSelections.length - 11].isChecked"
        >
          <template slot-scope="scope">
            {{ $filter.surfInterType(scope.row.netType) }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.ExternalIP')"
          prop="OUT_IP"
          v-if="tradeSelections[tradeSelections.length - 10].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.outIp }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.IntranetIP')"
          prop="IP"
          v-if="tradeSelections[tradeSelections.length - 9].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.ip }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="WIFI-SSID"
          prop="WIFI_SSID"
          v-if="tradeSelections[tradeSelections.length - 8].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiSsid }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="WIFI-MAC"
          prop="WIFI_MAC"
          v-if="tradeSelections[tradeSelections.length - 7].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiMac }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Username')"
          prop="LOGINNAME"
          v-if="tradeSelections[tradeSelections.length - 6].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Name')"
          prop="USERNAME"
          v-if="tradeSelections[tradeSelections.length - 5].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Section')"
          prop="GROUPFULLNAME"
          v-if="tradeSelections[tradeSelections.length - 4].isChecked"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{
              row.groupfullname.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('public.GenerationTime')"
          prop="CREATETIME"
          v-if="tradeSelections[tradeSelections.length - 3].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.time }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('public.ReportingTime')"
          prop="UPDATETIME"
          v-if="tradeSelections[tradeSelections.length - 2].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.updatetime }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('public.Controls')">
          <template slot-scope="scope">
            <span
              size="small"
              @click="details(scope.row)"
              style="color: #d38a08; cursor: pointer"
              >{{ $t("public.Details") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog
      v-if="outerVisible"
      @handleClose="handleClose"
      :title="$t('public.Details')"
      :dialogVisible="outerVisible"
      :flagbtn="false"
      :num="'470px'"
      :destroy-on-close="true"
    >
      <illegalbeDetail :detailData="detailData" />
    </Eldialog>
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import options from "@/utils/country-level3-data"; //三级城市数据
import { regionData } from "element-china-area-data"; //引入
import pagination from "@/components/pagination/page";
import Eldialog from "@/components/elDialog/index.vue";
import illegalbeDetail from "./components/illegalbeDetail.vue";
export default {
  components: {
    selectInput,
    Eldialog,
    pagination,
    illegalbeDetail,
  },

  data() {
    return {
      key: 1, //table  key
      total: 0,
      flag: 1,
      props: { multiple: true },
      number: 0,
      number1: 0,
      cityarrs: [],
      tbodyData: [], // 配置项数据
      formCustom: {
        Detail: "", //详细地址信息
        address: "", //省份城市
        DetailMap: "", //城市地址详细地址拼接
      },
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      cityOptions: options, // 城市数据
      pickerOptions1: {},
      pickerOptions0: {},
      tradeSelections: [
        { eng: "devicename", name: "设备名称", isChecked: true, flag: true },
        { eng: "type", name: "行为类型", isChecked: true, flag: true },
        { eng: "level", name: "行为级别", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: true, flag: false },
        { eng: "location", name: "位置", isChecked: true, flag: false },
        {
          eng: "longitudeAndLatitude",
          name: "经纬度",
          isChecked: false,
          flag: false,
        },
        { eng: "netType", name: "上网方式", isChecked: false, flag: false },
        { eng: "outIp", name: "外网IP", isChecked: true, flag: false },
        { eng: "ip", name: "内网IP", isChecked: false, flag: false },
        { eng: "wifiSsid", name: "WIFI-SSID", isChecked: false, flag: false },
        { eng: "wifiMac", name: "WIFI-MAC", isChecked: false, flag: false },
        { eng: "loginname", name: "用户名", isChecked: true, flag: false },
        { eng: "username", name: "姓名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: true, flag: false },
        { eng: "createtime", name: "产生时间", isChecked: true, flag: false },
        { eng: "time", name: "上报时间", isChecked: true, flag: true },
        { eng: "decide", name: "操作", isChecked: true, flag: true },
      ], // isChecked：true选中(默认)false不选中    flag：true是禁用(不可编辑)false不禁用(可编辑)
      tradeSelections1: [], // 联动
      groupFullNameShowByIndex: "", //用户部门截取的下标
      pageSize: 10, //每页显示条数
      tableData: [], //列表data
      orderRule: "DESC", //排序规则ASC、DESC
      seleShift: "devicename", // 选中下拉框转译
      formInline: {
        level: "", //下拉
        input: "",
        reason: "",
        endTime: "",
        devName: "", // 设备名称
        location: [],
        startTime: "", // 选择日期
        deviceName: "设备名称",
      },
      detailData: {}, //详情data
      typeOptions: [
        { type: "", label: "行为类型" },
        { type: "0", label: "设备越狱" },
        { type: "8", label: "SIM卡变更" },
        { type: "13", label: "SD卡变更" },
        { type: "9", label: "违规外联" },
        { type: "5", label: "地理围栏" },
        { type: "1", label: "系统版本" },
        { type: "7", label: "流量异常" },
        { type: "14", label: "设备电量" },
        { type: "15", label: "存储空间" },
        { type: "12", label: "必装应用" },
        { type: "2", label: "应用黑白名单" },
        { type: "33", label: "WIFI黑白名单" },
        { type: "36", label: "系统功能" },
      ],
      currentPage: 1, //当前页
      orderColume: "CREATETIME", //排序字段
      placeholder: "设备名称",
      outerVisible: false,

      levelOptions: [
        { value: "", label: "行为级别" },
        { value: "3", label: "高" },
        { value: "2", label: "中" },
        { value: "1", label: "低" },
      ], //行为级别
      devNameOptions: [
        { value: "devicename", label: "设备名称" },
        { value: "loginname", label: "用户名" },
        { value: "username", label: "姓名" },
        { value: "groupFullName", label: "部门" },
        // { value: "osVersion", label: "系统版本" },
      ], //设备名称
      selectedOptions: [], //存放默认值
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },

  created() {
    if (this.$route.query.cityName) {
      let cityArray = this.$route.query.cityName.split(",");
      let options = [
        this.$t("SecurityMonitor.Violation.BeiJing"),
        this.$t("SecurityMonitor.Violation.TianJin"),
        this.$t("SecurityMonitor.Violation.ShangHai"),
        this.$t("SecurityMonitor.Violation.ChongQing"),
      ];
      options.forEach((i, index) => {
        if (cityArray.length == 1) {
          if (cityArray[0] == i) {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, item.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          } else {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, items.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          }
        } else {
          if (cityArray[0] == i) {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    if (itemss.value == cityArray[1]) {
                      this.cityarrs.push([
                        item.value,
                        item.value,
                        itemss.value,
                      ]);
                    }
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          } else {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  if (items.value == cityArray[1]) {
                    items.children.forEach((itemss, indexss) => {
                      this.cityarrs.push([
                        item.value,
                        items.value,
                        itemss.value,
                      ]);
                    });
                  }
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          }
        }
      });
      this.onSubmit();
    }
  },

  //取token
  beforeMount() {
    const token = sessionStorage.getItem("userName");
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.formInline.startTime = this.$route.query.startTime;
    this.formInline.endTime = this.$route.query.startTime;
    if (this.$route.query.flag == false) {
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.startTime = this.$route.query.startTime.split(" ")[0];
      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == true) {
      this.formInline.startTime = this.$route.query.startTime.split(" ")[0];

      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == 2) {
      if (
        this.$route.query.startTime == "00" ||
        this.$route.query.startTime == "01" ||
        this.$route.query.startTime == "02" ||
        this.$route.query.startTime == "03" ||
        this.$route.query.startTime == "04" ||
        this.$route.query.startTime == "05" ||
        this.$route.query.startTime == "06" ||
        this.$route.query.startTime == "07" ||
        this.$route.query.startTime == "08" ||
        this.$route.query.startTime == "09" ||
        this.$route.query.startTime == "10" ||
        this.$route.query.startTime == "11" ||
        this.$route.query.startTime == "12" ||
        this.$route.query.startTime == "13" ||
        this.$route.query.startTime == "14" ||
        this.$route.query.startTime == "15" ||
        this.$route.query.startTime == "16" ||
        this.$route.query.startTime == "17" ||
        this.$route.query.startTime == "18" ||
        this.$route.query.startTime == "19" ||
        this.$route.query.startTime == "20" ||
        this.$route.query.startTime == "21" ||
        this.$route.query.startTime == "22" ||
        this.$route.query.startTime == "23" ||
        this.$route.query.startTime == "24"
      ) {
        this.formInline.startTime = this.$common
          .UTCformat(this.getdatatime())
          .split(" ")[0];
        this.formInline.endTime = this.$common
          .UTCformat(this.getdatatime())
          .split(" ")[0];
        this.onSubmit(); //再次调用查询接口
      } else {
        console.log("hwjakkkkk");
        this.formInline.startTime = this.$route.query.startTime;
        this.formInline.endTime = this.$route.query.startTime;
        this.onSubmit(); //再次调用查询接口
      }
      // 当天
    } else if (this.$route.query.flag == 3) {
      //违规行为分布图表
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.startTime = this.$route.query.startTime;

      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == 4) {
      //违规行为分布图表
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.startTime = this.$route.query.starttime;

      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.cityName && this.$route.query.startTime) {
      this.formInline.startTime = this.$route.query.startTime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.inpSearch();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == 1) {
      this.getDataList();
    } else {
      // 原代码日期
      this.formInline.startTime = this.$common.getAnyMonth("-", -1);
      this.formInline.endTime = this.$common.getDate();
      this.getDataList();
    }
  },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  },
  methods: {
    selectSearchChange() {},
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 默认显示当天时间
    getdatatime() {
      //默认显示今天
      return new Date().getTime();
    },
    //xxxx-xx-xx
    UTCformat(utc) {
      var date = new Date(utc),
        year = date.getFullYear(),
        month =
          date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + parseInt(date.getMonth() + 1),
        day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
        hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
        minutes =
          date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
        seconds =
          date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      var res = year + "-" + month + "-" + day + " ";
      return res;
    },

    // 排序@sort-change="sort"
    sort(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList();
    },
    tableTitleTime(i, k) {},
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "devicename" ||
          index == "reason" ||
          index == "level" ||
          index == "osVersion" ||
          index == "location" ||
          index == "longitudeAndLatitude" ||
          index == "netType" ||
          index == "outIp" ||
          index == "ip" ||
          index == "wifiSsid" ||
          index == "wifiMac" ||
          index == "username" ||
          index == "loginname" ||
          index == "groupfullname" ||
          index == "createtime" ||
          index == "time"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },
    changeDate() {
      let start = new Date(this.formInline.startTime).getTime();
      let end = new Date(this.formInline.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    // 列表
    async getDataList(val) {
      this.changeDate();
      if (this.$route.query.starttime) {
        this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
        this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      }
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        reason: this.formInline.reason,
        level: this.formInline.level,
        deviceName: "",
         
        location: location,
        customKey: this.selectVal ? this.selectVal.deviceName : this.seleShift,
        customVal: this.selectVal
          ? this.selectVal.InputText
          : this.formInline.input,
      };
      const res = await this.$axios.post(
        "/httpServe/violations/getDataInfo",
        parma,
        true
      );

      this.tableData = res.data.content;
      this.total = res.data.total;
      for (var i = 0; i < this.tableData.length; i++) {
        var obj2 = this.tableData[i];
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.typeOptions.length; j++) {
          if (this.tableData[i].type == this.typeOptions[j].type) {
            this.tableData[i].type = this.typeOptions[j].label;
          }
        }
      }
    },
    changeName(item) {
      this.formInline.deviceName = item;
      this.placeholder = item;
      this.devNameOptions.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
          console.log(this.seleShift);
        }
      });
    },
    chang1(val) {},
    // 查询
    inpSearch() {
      this.formInline.deviceName = this.$t("public.ApplicationName");
      this.placeholder = this.$t("public.ApplicationName");
      this.seleShift = "name";
      for (let index = 0; index < this.typeOptions.length; index++) {
        this.flagDlk = true;
        if (this.typeOptions[index].label == this.$route.query.cityName) {
          this.formInline.input = "";
          this.formInline.reason = this.typeOptions[index].type;
          this.flagDlk = false;
          break;
        }
        if (this.flagDlk) {
          this.formInline.reason = "";
          this.formInline.input = "";
        } else {
          this.formInline.reason = this.typeOptions[index].type;
          this.formInline.input = "";
        }
      }
      if (this.$route.query.cityName == "N/A") {
        this.formInline.input = "";
        this.formInline.location = [["N/A"]];
      } else if (
        this.$route.query.cityName ==
        this.$t("SecurityMonitor.Violation.Beyondbord")
      ) {
        this.formInline.input = "";
        this.formInline.location = [
          [this.$t("SecurityMonitor.Violation.Beyondbord")],
        ];
      }
    },
    //查询
    async onSubmit(val) {
      this.selectVal = val;
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      console.log(this.formInline.startTime, "this.formInline.startTime");
      this.currentPage = 1;
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime.split(" ")[0],
        endTime: this.formInline.endTime.split(" ")[0],
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        // reason: this.formInline.reason,
        reason: this.formInline.reason,
        level: this.formInline.level,
        deviceName: "",
        location: location,
         
        customKey: val ? val.deviceName : "",
        customVal: val ? val.InputText : "",
      };
      console.log(parma);
      const res = await this.$axios.post(
        "/httpServe/violations/getDataInfo",
        parma,
        true
      );
      // console.log(res);
      this.tableData = res.data.content;
      this.total = res.data.total;
      // 对于为空的字段，显示N/A
      for (var i = 0; i < this.tableData.length; i++) {
        var obj2 = this.tableData[i];
        // console.log(obj2, "obj2");
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.typeOptions.length; j++) {
          // console.log("内层循环" + this.typeOptions[j].type + "次");
          if (this.tableData[i].type == this.typeOptions[j].type) {
            this.tableData[i].type = this.typeOptions[j].label;
          }
        }
      }
    },
    // // 分页
    // handleSizeChange(val) {
    //   this.pageSize = val
    //   this.getDataList()
    // },
    // handleCurrentChange(val) {
    //   this.currentPage = val
    //   this.getDataList()
    // },
    //导出
    async advancedBtn() {
      let arrOut = [];
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != this.$t("public.Controls")) {
          arrOut.push(item.eng); //去重
          this.tradeSelections1 = [...new Set(arrOut)];
        }
      });
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMzNzU1NjQsInVzZXJJZCI6MSwiaWF0IjoxNjMyNjU1NTY0fQ.OtDA-uF3NfP8xqj902kqxPu54UPgs8SYVY1nBTl1Ifnvoxw_-MPmcN_DKeYDQXHZfEizztITgMABrO0tBmvshA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );

      let groupId = JSON.parse(userString).userId;

      this.userId = groupId;

      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      var a = JSON.stringify(this.formInline.location);
      var b = JSON.parse(a);

      var arr = [];
      if (this.formInline.location.length > 0) {
        arr = b[0].join("/");
      }

      var parma = {
        currentPage: this.currentPage,
        pageSize: this.total,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        reason: this.formInline.reason,
        level: this.formInline.level,
        location: location,
        deviceName: "",
         
        customKey: this.selectVal.deviceName
          ? this.selectVal.deviceName
          : this.seleShift,
        customVal: this.selectVal.InputText
          ? this.selectVal.InputText
          : this.formInline.input,
        exportField: this.tradeSelections1,
      };
      const res = await this.$axios.postExport(
        "/httpExport/violations/exportExcel",
        parma,
        true,
        this.userId
      );
      const link = document.createElement("a");
      console.info(new Date());

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportViolation_" + Date.parse(new Date()) + ".xls";

      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 详情
    details(info) {
      console.log(info,'info');
      this.outerVisible = true;
      this.detailData = info;
      this.detailData["decideStr"] = eval(this.detailData.decide);
    },
    //配置项监听事件
    tableTitleTime(event) {},
  },
};
</script>

<style lang="scss" scoped>
.SensitiveMonitor {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
@-moz-document url-prefix() {
  .location {
    &::v-deep {
      & ::placeholder {
        color: #606266;
        opacity: 1 !important; /*火狐默认有opacity:0.4; */
      }
    }
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 130px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px;
}
.el-form-item__content {
  width: 100%;
  display: flex;
  margin: 0 !important;
}
.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
.placeInput {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-cascader__tags .el-cascader__search-input::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep
    .el-cascader__tags
    .el-cascader__search-input::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-cascader__tags .el-cascader__search-input::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-cascader__tags .el-cascader__search-input:-ms-input-placeholder {
    color: #606266 !important;
  }
}

::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>